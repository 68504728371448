<template>
  <div class="approval-form">
    <div style="padding: 20px 10px 16px 45%;" v-if="isEdit" class="leave-off">
      <van-button block round @click="leaveConfirm=true"  type="default">退房</van-button>
    </div>
    <van-form @submit="onSubmit" @failed="failed"
              :scroll-to-error="true" label-align="right" ref="checkForm">
      <div class="form-pannel">
        <div class="title"><b class="title-icon"></b>住客信息采集
          <span style="display:inline-block;position:absolute;right:10px;font-size:14px;color: #0E9869; ">
            登记时间&nbsp;({{formatTime(show.collectTime)}})
          </span>
        </div>
        <div style="padding-left:10px;">
          <div class="form-label mt10" style="padding-bottom: 10px;"><span class="label-tips">* </span> 身份信息
            &nbsp;&nbsp;
            <van-uploader  :before-read="beforeRead" :after-read="recognize" accept="image/*">
              <van-button class="photo-btn" icon="photograph" size="small" >身份证识别</van-button>
            </van-uploader>
          </div>
          <van-row class="id-info">
            <van-col span="10">
              <van-field
                  name="name"
                  label-width="1.8rem"
                  label="姓名"
                  :rules="[{ required: true, message: '必填' }]"
                  v-model="show.name"
              >
              </van-field>
            </van-col>
            <van-col span="7">
              <van-field
                  name="sex"
                  label-width="1.8rem"
                  label="性别"
                  :rules="[{ required: true, message: '必填' }]"
                  v-model="show.sex"
              >
              </van-field>
            </van-col>
            <van-col span="7">
              <van-field
                  name="national"
                  label-width="1.8rem"
                  label="民族"
                  :rules="[{ required: true, message: '必填' }]"
                  v-model="show.national"
              >
              </van-field>
            </van-col>
            <van-col span="24">
              <van-field
                  name="identity"
                  label-width="4rem"
                  label-align="left"
                  label="身份号码"
                  :rules="[{ required: true, message: '必填' }]"
                  v-model="show.identity"
              >
              </van-field>
            </van-col>
            <van-col span="24">
              <van-field
                  name="address"
                  label-width="4rem"
                  label-align="left"
                  rows="2"
                  autosize
                  type="textarea"
                  label="住址"
                  :rules="[{ required: true, message: '必填' }]"
                  v-model="show.address"
              >
              </van-field>
            </van-col>
            <van-col span="24">
              <van-field
                  label-width="4rem"
                  label-align="left"
                  v-model="show.mobile"
                  label="联系方式"
                  name="mobile" placeholder="请输入手机号"
                  :rules="[{ validator:validatorPhone,required: false, message: '填入正确的手机号'}]"/>

            </van-col>
          </van-row>

<!--          <div class="form-label mt10"><span class="label-tips">* </span> 入住人姓名</div>
          <van-field v-model="show.name" :disabled="isView"
                     name="name" placeholder="姓名" :border="false"
                     :rules="[{ required: true, message: '必填'}]" class="custom-style"/>
          <div class="form-label mt10"><span class="label-tips">* </span> 上传身份证</div>
          <van-field name="remark" :disabled="isView" :rules="[{ required: true, message: '上传身份证照片' }]">
            <template #input>
              <van-uploader v-model="show.remark" :disabled="isView" :deletable="!isView" name="remark"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="recognize" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>-->
<!--          <div class="form-label mt10"><span class="label-tips">* </span> 上传健康码</div>
          <van-field name="healthCode" :disabled="isView" :rules="[{ required: true, message: '上传健康码图片' }]">
            <template #input>
              <van-uploader v-model="show.healthCode" :disabled="isView" :deletable="!isView" name="healthCode"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>-->


<!--          <van-field name="natUrl" :disabled="isView" :rules="[{ required: true, message: '请上传健康码(72小时核酸检测证明)' }]">
            <template #input>
              <van-uploader v-model="show.natUrl" :disabled="isView" :deletable="!isView" name="natUrl"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>-->
          <div class="form-label mt10"><span class="label-tips">* </span> 健康码(48小时核酸检测证明)</div>
          <van-field name="natUrl" :disabled="isView" :rules="[{ required: true, message: '请上传健康码(72小时核酸检测证明)' }]">
            <template #input>
              <van-uploader v-model="show.natUrl" :disabled="isView" :deletable="!isView" name="natUrl"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>
          <div class="form-label mt10"><span class="label-tips">* </span> 上传行程卡</div>
          <van-field name="travelCode" :disabled="isView" :rules="[{ required: true, message: '上传行程卡图片' }]">
            <template #input>
              <van-uploader v-model="show.travelCode" :disabled="isView" :deletable="!isView" name="travelCode"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>
          <div class="form-label mt10"><span class="label-tips">* </span> 7天内行程</div>
          <van-field v-model="show.travelCities"
                     readonly
                     :disabled="isView" clickable  @click="showPicker1 = true"
                     name="travelCities" placeholder="7天内行程" :border="false"
                     :rules="[{ required: true, message: '必填'}]" class="custom-style"/>
          <van-popup v-model="showPicker1" position="bottom" get-container="#app">
            <van-picker
                show-toolbar
                :columns="infoList"
                @confirm="onConfirm1"
                @cancel="showPicker1 = false"
            />
          </van-popup>

<!--          <div class="form-label mt10"><span class="label-tips">* </span> 来（返）宁人员信息申报完成情况</div>
          <van-field
              name="isReport"
              placeholder="来（返）宁人员信息申报完成情况"
              :rules="[{ required: true, message: '必填'}]"
          >
            <template #input>
              <van-radio-group v-model="show.isReport" direction="horizontal">
                <van-radio name="是" icon-size="24px">已申报</van-radio>
                <van-radio name="否" icon-size="24px">未申报</van-radio>
              </van-radio-group>
            </template>

          </van-field>-->
          <div  v-if="show.travelCities!='南京市'">
            <div class="form-label mt10"> 来（返）宁人员信息申报完成证明</div>
<!--            <van-field name="reportUrl" :disabled="isView" :rules="[{ validator:validatorPic, message: '请上传来（返）宁人员信息申报完成证明' }]">-->
            <van-field name="reportUrl" :disabled="isView" :rules="[{ required:false, message: '请上传来（返）宁人员信息申报完成证明' }]">
              <template #input>
                <van-uploader v-model="show.reportUrl" :disabled="isView" :deletable="!isView" name="reportUrl"  :max-count="1"  @delete="del"
                              :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
              </template>
            </van-field>
          </div>

          <div class="form-label mt10"><span class="label-tips"></span> 高风险地区比对</div>
          <div class="area-compare">
            <div class="left">
              <van-field v-model="province"
                         readonly
                         :disabled="isView" clickable  @click="showPicker3 = true"
                         placeholder="省市" :border="false"
                         class="custom-style"/>
              <van-popup v-model="showPicker3" position="bottom" get-container="#app">
                  <van-area title="选择省市" :area-list="areaList" :columns-num="2" @confirm="onConfirm4" @cancel="showPicker3 = false" :value="provinceId" />
              </van-popup>
            </div>
            <div class="right">
              <van-field v-model="show.cities"
                         :disabled="isView"
                         name="cities" placeholder="请输入具体地址" :border="false"
                         :rules="[{ required: false, message: '必填'}]" class="custom-style">
                <template #button>
                  <!--              <van-button size="small" type="primary">比对</van-button>-->
                  <span @click="compare" style="display: inline-block;width:50px;border-radius:4px;text-align: center;color: white;background: #349dff;">比对</span>
                </template>
              </van-field>
              <van-popup v-model="showPicker4" position="bottom"  :style="{ height: '50%' }" get-container="#app">
<!--                <van-area class="high-risk-area" title="高风险地区列表" :area-list="areaList1" :columns-num="2" @confirm="onConfirm5" @cancel="showPicker4 = false"  />-->
                <van-picker show-toolbar title="高风险地区列表" :columns="areaList1"  @confirm="onConfirm5" @cancel="showPicker4 = false" />
              </van-popup>
            </div>
          </div>
          <div class="form-label mt10"> 是否高风险地区</div>
          <van-field
              name="isHighRiskArea"
              placeholder="选择是否来自高风险地区"
              :rules="[{ required: false, message: '必填'}]"
          >
            <template #input>
              <van-radio-group v-model="show.isHighRiskArea" direction="horizontal">
                <van-radio name="是" icon-size="24px">是</van-radio>
                <van-radio name="否" icon-size="24px">否</van-radio>
              </van-radio-group>
            </template>

          </van-field>


<!--          <div class="form-label mt10"><span class="label-tips">* </span> 上传48内核酸检测信息</div>
          <van-field name="natUrl" :disabled="isView" :rules="[{ required: true, message: '请上传48内核酸检测证明' }]">
            <template #input>
              <van-uploader v-model="show.natUrl" :disabled="isView" :deletable="!isView" name="natUrl"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>-->

<!--          <div class="form-label mt10"><span class="label-tips">* </span> 最后一次核酸检测时间</div>
          <van-field v-model="form.natTime" :disabled="isView"
                     name="natTime" placeholder="最后一次核酸检测时间" :border="false"
                     :rules="[{ required: true, message: '必填'}]" class="custom-style"/>
            <van-field
                readonly
                clickable
                :disabled="isView" :rules="[{ required: true, message: '必填' }]"
                :border="false"
                class="custom-style" right-icon="arrow-down"
                name="natTime"
                :value="show.natTime"
                placeholder="点击选择时间"
                @click="showPicker = true"
            />-->
            <van-popup v-model="showPicker" position="bottom" get-container="#app">
              <van-datetime-picker
                  type="date"
                  v-model="natTime"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirm"
                  @cancel="showPicker = false"
              />
            </van-popup>
          <div v-if="show.travelCities!='南京市'">
            <div class="form-label mt10">上传落地检证明</div>
            <van-field name="landInspect" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
              <template #input>
                <van-uploader v-model="show.landInspect" :disabled="isView" :deletable="!isView" name="landInspect"  :max-count="1"  @delete="del"
                              :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
              </template>
            </van-field>
          </div>

        </div>
      </div>


      <div class="form-pannel mt10" v-if="show.travelCities!='南京市'">
<!--        <div class="title"><b class="title-icon"></b>外省市客人“三天三检”</div>-->
        <div class="title"><b class="title-icon"></b>健康信息</div>
        <van-field v-model="show.remark"
                   readonly
                   :disabled="isView" clickable  @click="showPicker2 = true"
                   name="remark" placeholder="请选择核酸检测要求" :border="false"
                   :rules="[{ required: false, message: '请选择核酸检测要求'}]" class="custom-style"/>
        <van-popup v-model="showPicker2" position="bottom" get-container="#app">
          <van-picker
              show-toolbar
              :columns="infoList1"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
          />
        </van-popup>


        <div class="form-label mt10">上传第一次核酸证明</div>
        <van-field name="inspectFirst" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
          <template #input>
            <van-uploader v-model="show.inspectFirst" :disabled="isView" :deletable="!isView" name="inspectFirst"  :max-count="1"  @delete="del"
                          :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
          </template>
        </van-field>

        <div>
          <div class="form-label mt10">上传第二次核酸证明</div>
          <van-field name="inspectSecond" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
            <template #input>
              <van-uploader v-model="show.inspectSecond" :disabled="isView" :deletable="!isView" name="inspectSecond"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传文件'" class="custom-upload"/>
            </template>
          </van-field>
        </div>

        <div v-if="show.remark=='三天三检' || show.remark=='七天五检'">
          <div class="form-label mt10">上传第三次核酸证明</div>
          <van-field name="inspectThird" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
            <template #input>
              <van-uploader v-model="show.inspectThird" :disabled="isView" :deletable="!isView" name="inspectThird"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传图片'" class="custom-upload"/>
            </template>
          </van-field>
        </div>


        <div v-if="show.remark=='七天五检'">
          <div class="form-label mt10">上传第五天核酸证明</div>
          <van-field name="inspectFifth" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
            <template #input>
              <van-uploader v-model="show.inspectFifth" :disabled="isView" :deletable="!isView" name="inspectFifth"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传文件'" class="custom-upload"/>
            </template>
          </van-field>

          <div class="form-label mt10">上传第七天核酸证明</div>
          <van-field name="inspectSeventh" :disabled="isView" :rules="[{ required: false, message: '至少上传一个文件' }]">
            <template #input>
              <van-uploader v-model="show.inspectSeventh" :disabled="isView" :deletable="!isView" name="inspectSeventh"  :max-count="1"  @delete="del"
                            :before-read="beforeRead" :after-read="sendFile" accept="image/*" :upload-text="'上传文件'" class="custom-upload"/>
            </template>
          </van-field>
        </div>
      </div>

      <div class="btn-groups">
        <van-button block :disabled="submitDisabled" :loading="loading" color="#0E9869" native-type="submit" size="large" class="thebtn">保存</van-button>
      </div>
    </van-form>


    <!-- 保存确认弹框 -->
    <van-popup v-model="volConfirm" round @close="cancelConfirm" position="bottom"  :style="{ height: '30%' }" get-container="#app" class="confirm-pop">
      <div class="confirm-title">确认保存顾客信息？</div>
      <div class="theBtns" style="display: flex;justify-content: center;">
        <van-button  @click="cancelConfirm" style="width:80px;">返回</van-button>
        <van-button @click="saveConfirm" color="#0E9869" style="width:80px;">保存</van-button>
      </div>
    </van-popup>
    <!-- 退房确认弹框 -->
    <van-popup v-model="leaveConfirm" round @close="leaveConfirm=false" position="bottom"  :style="{ height: '30%' }" get-container="#app" class="confirm-pop">
      <div class="confirm-title">确认退房吗？</div>
      <div class="theBtns" style="display: flex;justify-content: center;">
        <van-button  @click="leaveConfirm=false" style="width:80px;">返回</van-button>
        <van-button @click="leaveOff" color="#0E9869" style="width:80px;">确认</van-button>
      </div>
    </van-popup>

    <!-- 保存成功弹框 -->
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }" get-container="#app">
      <div class="success-icon">
        <van-icon name="passed" color="#00E68F" size="40" />
      </div>
      <div class="success-content">提交成功，{{seconds}}s<a href="javascript:void(0)" @click="closePup">关闭</a></div>
    </van-popup>
    <van-overlay :show="leaveLoading" @click="leaveLoading = false">
      <div class="wrapper" @click.stop>
        <van-loading size="32px" color="#1989fa">正在处理...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Compressor from 'compressorjs';
import { areaList } from '@vant/area-data';
import {
  CellGroup,Cell,Form,Search,Field,RadioGroup,Radio,Checkbox,CheckboxGroup,DatetimePicker,Loading,Row,Col,
  Icon,Picker,Calendar,Uploader,Button,NumberKeyboard,Popup,Notify,Toast, Divider,Overlay,Area
} from 'vant';
export default {
  name:'personal-task',
  components:{
    [CellGroup.name]: CellGroup,
    [DatetimePicker.name]: DatetimePicker,
    [Cell.name]: Cell,
    [Area.name]: Area,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Search.name]: Search,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Calendar.name]: Calendar,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard,
    [Popup.name]: Popup,
    [Notify.name]: Notify,
    [Toast.name]: Toast,
    [Divider.name]: Divider,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  props: {

    dict: {
      type: Object,
      default: () => {
        return {}
      }
    },
    recordData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      areaList,
      areaList1:{},
      seconds: 3,
      showPicker4:false,
      volConfirm:false,
      isView: false,
      isEdit:false,
      loading:false, //保存load
      leaveLoading:false, //退房
      leaveConfirm:false, //退房
      leaveData:{},
      submitDisabled:false,
      show: {
        name:undefined,
        identity:undefined,
        sex:undefined,
        national:undefined,
        address: undefined,
        collectTime: new Date(),
        leaveTime: "",
        natTime: undefined,
        mobile: undefined,
        isComplete: "0",
        healthCode: [],
        travelCode: [],
        natUrl: [],
        reportUrl: [],
        landInspect: [],
        remark: "三天两检",
        inspectFirst: [],
        inspectSecond: [],
        inspectThird: [],
        inspectFifth: [],
        inspectSeventh: [],
        travelCities: "本省外市",
        cities: "",
        isReport: "是",
        isHighRiskArea:"否"
      },
      province:"江苏省/南京市",
      provinceId:"320100",
      successPop:false,
      showPicker:false,
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      natTime: new Date(),
      showPicker1:false,
      showPicker3:false,
      infoList: ['外省市', '本省外市', '南京市'],
      showPicker2:false,
      // infoList1: ['三天两检', '三天三检', '七天五检'],
      infoList1: ['三天两检', '三天三检'],
    }
  },
  watch:{

    "recordData": {
      handler(n, o) {
        console.log(n,"load recordData")
        if(n){
          this.isEdit = true;
          this.leaveData = JSON.parse(JSON.stringify(n));
          this.loadForm(n);
        }else{
          this.isEdit = false;
          this.resetForm();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods:{

    loadForm(n) {
      let healthCode= [],
          travelCode= [],
          natUrl= [],
          reportUrl= [],
          landInspect= [],
          inspectFirst= [],
          inspectSecond= [],
          inspectThird= [],
          inspectFifth= [],
          inspectSeventh= [];
      if(n.healthCode){
        healthCode = n.healthCode.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.travelCode){
        travelCode = n.travelCode.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.landInspect){
        landInspect = n.landInspect.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.natUrl){
        natUrl = n.natUrl.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      /*if(n.remark){
        remark = n.remark.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }*/
      if(n.inspectFirst){
        inspectFirst = n.inspectFirst.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.inspectSecond){
        inspectSecond = n.inspectSecond.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.inspectThird){
        inspectThird = n.inspectThird.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.inspectFifth){
        inspectFifth = n.inspectFifth.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.inspectSeventh){
        inspectSeventh = n.inspectSeventh.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      if(n.reportUrl){
        reportUrl = n.reportUrl.split(",").map(url=>{
          return {
            url:url,
            isImage:true
          }
        })
      }
      this.show = {
        ...n,
        collectTime: n.collectTime?n.collectTime.substr(0,16):"",
        natTime:n.natTime?n.natTime.substr(0,10):"",
        healthCode:healthCode,
        reportUrl:reportUrl,
        travelCode:travelCode,
        natUrl:natUrl,
        landInspect:landInspect,
        inspectFirst:inspectFirst,
        inspectSecond:inspectSecond,
        inspectFifth:inspectFifth,
        inspectSeventh:inspectSeventh,
        inspectThird:inspectThird
      }
    },
    onConfirm5(value){
      if(value){
        this.show.cities = value.join("");
      }
      this.showPicker4 = false;
    },
    keyAdd(index,num){
      index = index+1;
      let str = "";
      if(index<10){
        str = "00" + index;
      }
      return str;
    },
    // 验证手机号格式是否正确
    validatorPhone(val,rule){
      if(!val){
        return true;
      }
      if(!(/^1[3456789]\d{9}$/.test(val))){
        return false;
      }
      return true;
    },
    // 验证图是否必填
    validatorPic(val,rule){
      if(this.show.isReport=='是'){
        return (Array.isArray(val)&&val.length>0)?true:false;
      }
      return true;
    },
    compare(){
      let province = "",city='';
      if(this.province){
        if(this.province=='北京市/北京市'){
          province = "北京市"
        }else{
          province = this.province.split("/")[0];
          city = this.province.split("/")[1];
        }
      }
      this.$http({
        url: "/zuul/cultural/riskArea/search?area=" + this.show.cities,
        method: "post",
        params:{
          province:province,
          city:city
        }
      }).then(res => {
        console.log(res,"this.show.cities")
        let arr = [];
        if (res.data.success) {
            res.data.data.map((item,index)=>{
              // arr.province_list[index + "0"] = item.areaName;
              // arr.province_list[this.keyAdd(index) + '0000'] = item.county;
              let communites = JSON.parse(item.communitys).map(c=>{
                return {
                  text:c
                }
              });
              arr.push({
                text:item.county,
                children:communites
              })
            })
          this.areaList1 = arr;
          this.showPicker4 = true;
        }
      }).catch(error => {
        Notify({type: 'warning', message: '请求失败:' + error});
      }).finally(f => {
      })
    },
    //选择省市
    onConfirm4(value,key){
      console.log(value,key);
      if(value && value.length>1){
        this.province = value.map(item=>item.name).join("/");
        this.provinceId = value[1].code;
      }
      this.showPicker3 = false;
    },
    // 高风险比对
    onConfirm3(value, index){
      this.show.cities = value;
      this.showPicker3 = false;
    },
    // 行程选择
    onConfirm1(value, index){
        this.show.travelCities = value;
        if(this.show.travelCities=='外省市'){
          this.show.remark = '三天三检';
        }else  if(this.show.travelCities=='本省外市'){
          this.show.remark = '三天两检';
        }
        this.showPicker1 = false;
    },
    // 核酸选择
    onConfirm2(value, index){
      this.show.remark = value;
      this.showPicker2 = false;
    },
    // 时间选择
    onConfirm(date){
      console.log(date,"getDate")
      this.show.natTime = this.formatDate(date);
      this.showPicker = false;
    },
    // 退房
    leaveOff(){
      this.leaveLoading = true;
      this.leaveConfirm = false;
      this.$http({
        url: "/zuul/cultural/customerHealth",
        method: "post",
        data: {
          ...this.leaveData,
          leaveTime:this.formatTime1(new Date()),
          isComplete:1
        }
      }).then(res => {
        if (res.data.success) {
          Notify({type: 'success', message: '退房成功'});
          this.successTip();
        } else {
          Notify({type: 'warning', message: '退房失败:' + res.data.message});
        }
      }).catch(error => {
        Notify({type: 'warning', message: '请求失败:' + error});
      }).finally(f => {
        this.leaveLoading = false;
      })
    },
    // 日期格式化
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // 时间格式化
    formatTime(date) {
      let str = ""
      if(typeof(date)=='string'){
        str = date;
      }else{
        let h = date.getHours();
        h=h<10?("0"+h):h;
        let m = date.getMinutes();
        m=m<10?("0"+m):m;
        str = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}  ${h}:${m}`;
      }
      return str;
    },
    // 时间格式化
    formatTime1(date) {
      let str = ""
      if(!date){
        date = new Date();
      }
      if(typeof(date)=='string'){
        str = date;
      }else{
        let h = date.getHours();
        h=h<10?("0"+h):h;
        let m = date.getMinutes();
        m=m<10?("0"+m):m;
        let s = date.getSeconds();
        s=s<10?("0"+s):s;
        str = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}  ${h}:${m}:${s}`;
      }
      return str;
    },
    failed(rule) {
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    //重置表单
    resetForm(){
      this.show = {
        infoId:localStorage.getItem('wljptinfoId'),
        name:"",
        identity:"",
        sex:"",
        national:"",
        address: "",
        collectTime: this.formatTime(new Date()),
        leaveTime: "",
        natTime: "",
        isComplete: "0",
        healthCode: [],
        reportUrl: [],
        travelCode: [],
        natUrl: [],
        landInspect: [],
        remark: "三天两检",
        inspectFirst: [],
        inspectSecond: [],
        inspectFifth: [],
        inspectSeventh: [],
        inspectThird: [],
        travelCities: "本省外市",
        cities: "",
        isReport: "是",
        isHighRiskArea:"否"
      }
      this.successPop = false;
    },
    onSubmit(){
      this.volConfirm = true;
    },
    //保存：取消保存
    cancelConfirm(){
      this.volConfirm = false;
      this.loading = false;
    },
    saveConfirm(){
      console.log(this.show,"save");
      if(!localStorage.getItem("wljptuser")){
        Notify({type: 'warning', message: '登陆失效，请重新登陆！'});
        return false;
      }
      let healthCode= "",
          travelCode= "",
          natUrl= "",
          reportUrl= "",
          landInspect= "",
          inspectFirst= "",
          inspectSecond= "",
          inspectFifth= "",
          inspectSeventh= "",
          inspectThird= "";
      if(Array.isArray(this.show.healthCode)&&this.show.healthCode.length>0){
        healthCode = this.show.healthCode.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.natUrl)&&this.show.natUrl.length>0){
        natUrl = this.show.natUrl.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.travelCode)&&this.show.travelCode.length>0){
        travelCode = this.show.travelCode.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.landInspect)&&this.show.landInspect.length>0){
        landInspect = this.show.landInspect.map(item=>item.url).join(",");
      }

      if(Array.isArray(this.show.inspectFirst)&&this.show.inspectFirst.length>0){
        inspectFirst = this.show.inspectFirst.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.inspectSecond)&&this.show.inspectSecond.length>0){
        inspectSecond = this.show.inspectSecond.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.inspectThird)&&this.show.inspectThird.length>0){
        inspectThird = this.show.inspectThird.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.inspectFifth)&&this.show.inspectFifth.length>0){
        inspectFifth = this.show.inspectFifth.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.inspectSeventh)&&this.show.inspectSeventh.length>0){
        inspectSeventh = this.show.inspectSeventh.map(item=>item.url).join(",");
      }
      if(Array.isArray(this.show.reportUrl)&&this.show.reportUrl.length>0){
        this.show.isReport = "是";
        reportUrl = this.show.reportUrl.map(item=>item.url).join(",");
      }else{
        this.show.isReport = "否";
      }
      let collectTime = "";
      if(this.show.collectTime){
        collectTime = this.show.collectTime + ":00";
      }else{
        collectTime = this.formatTime1(new Date());
      }
      console.log(collectTime,"collectTime")
      let temp = {
        ...this.show,
        cities:this.province + this.show.cities,
        collectTime:collectTime,
        natTime:this.show.natTime?(this.show.natTime+' 00:00:00'):'',
        healthCode:healthCode,
        travelCode:travelCode,
        natUrl:natUrl,
        landInspect:landInspect,
        inspectFirst:inspectFirst,
        reportUrl:reportUrl,
        inspectSecond:inspectSecond,
        inspectFifth:inspectFifth,
        inspectSeventh:inspectSeventh,
        inspectThird:inspectThird,
        infoId:localStorage.getItem('wljptinfoId'),
        collectBy:localStorage.getItem("wljptuser")
      };
      this.$http({
        url: "/zuul/cultural/customerHealth",
        method: "post",
        data: temp
      }).then(res => {
        if (res.data.success) {
          Notify({type: 'success', message: '保存成功'});
          this.successTip();
        } else {
          Notify({type: 'warning', message: '保存失败:' + res.data.message});
        }
      }).catch(error => {
        Notify({type: 'warning', message: '请求失败:' + error});
      }).finally(f => {
        this.loading = false;
        this.volConfirm = false;
      })
    },

    closePup(){
      this.resetForm();
      window.clearInterval(this.timer);
      this.timer=null;
      this.seconds=3;
      this.$emit("closeReport");
    },
    // 成功提示框
    successTip() {
      this.successPop = true;
      this.seconds = 3;
      this.timer = setInterval(() => {
        this.seconds--;
        if (this.seconds <= 0) {
          this.resetForm();
          window.clearInterval(this.timer);
          this.$emit("closeReport")
        }
      }, 1000);
    },
    // 上传并压缩照片
    beforeRead(file, defaultQuqlity = 0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }

      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    del(file){
      if(file.url){
        let url = file.url.replace('/cultural/view?name=','/cultural/remove?name=')
        this.$http({
          url: url,
          method: "delete"
        }).then(res=>{
          if(res.data.success){
            console.log("删除成功")
          }
        })
      }
    },
    // 身份证识别
    recognize(file, detail){

      let name = detail.name;
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name, file.file);
      this.leaveLoading = true;
      this.$http({
        url: "/zuul/cultural/customerHealth/recognize",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data: formData
      }).then(res => {
        if (res.data.success) {
          console.log(res.data.data,"身份认证")
          this.show.name = res.data.data.name;
          this.show.identity = res.data.data.idNumber;
          this.show.national = res.data.data.ethnicity;
          this.show.address = res.data.data.address;
          this.show.sex = res.data.data.sex;
        }else{
          Notify({type: 'warning', message: '未获取到身份信息：' + error});
        }
      }).catch(error => {
        Notify({type: 'warning', message: '上传图片失败：' + error});
      }).finally(f => {
        this.leaveLoading = false;
      })
    },
    //上传文件
    sendFile(file, detail) {
      let name = detail.name;
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name, file.file);
      this.submitDisabled = true;
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data: formData
      }).then(res => {
        if (res.data.success) {
          if (res.data.data && res.data.data.length > 0) {
            this.show[name][this.show[name].length > 0 ? (this.show[name].length - 1) : 0].url =  '/zuul' + res.data.data[0].viewUrl;
          }
        }
      }).catch(error => {
        Notify({type: 'warning', message: '上传图片失败：' + error});
      }).finally(f => {
        this.submitDisabled = false;
      })
    }
  },
  mounted(){
  }
}
</script>
<style scoped lang="less">

@import './css/iconfont.css';
/deep/ .van-cell{
  padding: 10px 6px !important;
}
/deep/ .van-loading{
  line-height: calc(100vh);
  text-align: center;
}
/deep/ .leave-off .van-button--default{
  border: 1px solid #ebedf0;
}
.photo-btn{
  border: 1px solid #ebedf0 !important;
}
/deep/ .id-info .van-field__control{
  border: 1px solid #bbbcbd !important;
  border-radius: 2px !important;
  padding-left: 5px;
}


/deep/ .van-field--disabled .van-field__label{
  color:#858585 !important;
}
.mt10{ margin-top:10px; }
.pl8{  padding-left:8px; }
.processBtn{
  height: 50px;
  display: flex;
  flex-flow:column nowrap;
  align-items: center;
  justify-content: center;
  color:#0E9869;
  font-size: 18px;
}
.form-label{
  height: 24px;
  display: flex;
  align-items:center;
  color:#617387;
  font-weight: 500;
  font-size: 14px;
  .label-tips{
    color: red;
  }
}
.custom-style{
  border: 1px solid rgba(213, 219, 228, 0.5);
  border-radius: 2px;
}
.approval-form{

  .form-pannel{
    background: #fff;
    box-shadow: 0px 2px 6px 0px #e1e6ec;
    border-radius: 2px;
    padding:10px;
  }
  .title{
    height:30px;
    display: flex;
    align-items:center;
    color: #3C4A62;
    font-size: 18px;
    font-weight: 600;
    background: #fff;
    .title-icon{
      width: 4px;
      height: 18px;
      margin-right: 10px;
      border-radius: 2px;
      background: #0E9869;
      box-shadow: 0px 2px 4px 0px #33A27C;
    }
  }
  .add-btn{
    height: 40px;
    line-height: 40px;
    text-align: center;
    color:#1890FF;
    border:1px dashed #1890FF;
    border-radius: 4px;

  }
  .minus-icon{
    height: 24px;
    width:24px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    left:0;
    border:1px solid #ff4d4f;
    border-radius: 50%;
  }
  .btn-groups{
    margin-top:15px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    width: 100%;
    .thebtn{
      width:80%;
      height: 40px;
      line-height:40px;
      margin-right:10px;
      text-align:center;
      color:#fff;
      border-radius:20px;
    }
  }
  .processBtn{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
  }
}
.success-icon{
  text-align: center;
  position: relative;
  top: calc(50vh - 100px);
}
.success-content{
  padding: calc(50vh - 80px) 20px 0px 20px;
  text-align: center;
}
.popup-btn {
  height: 30px;
  button {
    height: 100%;
    padding: 0 16px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .btn-cancle {
    color: #969799;
  }
  .btn-confirm {
    color: #576b95;
    float: right;
  }
}
.confirm-pop{
  .confirm-title{
    padding: 32px 0px 24px;
    height: 30px;
    line-height:30px;
    text-align:center;
    background:#fff;
  }
  .confirm-cont{
    max-height:calc(100vh - 100px);
    overflow-y: auto;
    .confirm-pannel{
      padding:10px;
      background: #f6f6f6;
      box-shadow: 0px 2px 6px 0px #e1e6ec;

      .confirm-item{
        padding:10px 5px 10px 10px;
        display:flex;
        flex-flow:row nowrap;
        background: #fff;
        align-items:center;
      }
      .thelabel{
        width:125px;
        color:#617387;
      }
      .thevalue{
        width:calc(100vw - 145px);
      }
    }
  }
}
.process-pop{
  border-radius: 4px 4px 0 0;
  .process-content{
    max-height: calc(100vh - 52px);
    overflow-y:auto;
  }
  .process-opinion{
    margin-left:15px;
    position: relative;
    .opinion-pannel{
      padding-left:10px;
      padding-bottom: 20px;
    }
    .opinion-pannel:first-child .theDate{
      color:#0E9869;
    }
    .opinion-pannel:not(:last-child){
      border-left:1px solid #D8D8D8;
      position: relative;
    }
    .opinion-pannel::before{
      content: '';
      position: absolute;
      left:-5px;
      width:8px;
      height: 8px;
      border-radius: 50%;
      background: #0E9869;
      border: 1px solid #D8D8D8;
      box-shadow: 0px 2px 10px 0px rgba(14, 152, 105, 0.5);
    }
    .opinion-item{
      display: flex;
      flex-flow:row nowrap;
      align-items: center;
      margin-top:5px;
      /* border:1px solid red; */
    }
    .theLabel{
      padding-right:10px;
      color:#999;
    }
  }
  .sendBtn{
    width:calc(100% - 40px);
    margin-top:10px;
    margin-left: 20px;
    padding:10px 0;
    text-align: center;
    color:#fff;
    background: #0E9869;
    border-radius: 24px;
  }

}
.area-compare{
  >div{
    display: inline-block;
  }
  .left{
    width: calc(30vw);
    padding-right: 10px;
  }
  .right{
    width: calc(70vw - 40px);
  }
}
</style>
<style>
.custom-upload .van-uploader__upload{
  border: 1px solid rgba(213, 219, 228, 0.5);
  border-radius: 2px;
}
</style>


